import {
  Div,
  Coldisplay,
  ImgTextSignUp,
  Text,
  ImageDesk,
  TextRegister,
} from "./styles";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Col, Row } from "react-bootstrap";
import { useWindowSize } from "@hooks/useWindowSize";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { useAuth } from "@contexts/AuthContext";
import { useMain } from "@contexts/mainContext";

export const ImageRegister: React.FC = ({}) => {
  const [isMobil, setIsMobil] = useState(false);
  const { setSignUpShow } = useMain();

  const size = useWindowSize();
  const { getLangLabel } = useWhiteLabelLan();

  useEffect(() => {
    if (size.width <= 1024) {
      setIsMobil(true);
    } else {
      setIsMobil(false);
    }
  }, [size]);
  const { isLogged } = useAuth();
  const registerMobil = "/images/register/logo.png";
  if (isLogged) {
    return <div />;
  } else {
    return (
      <Div fluid>
        {isMobil ? (
          <ImgTextSignUp
            onClick={() => {
              setSignUpShow(true);
            }}
          >
            <Image
              alt="logo"
              src={registerMobil}
              layout="responsive"
              width={150}
              height={100}
              priority
            />
            <Text>{getLangLabel("REGISTER")}</Text>
          </ImgTextSignUp>
        ) : (
          <Row
            className="justify-content-md-center image-register"
            style={{ margin: 0 }}
          >
            {/* <Coldisplay xs={0} md={0} lg={3}></Coldisplay> */}
            <Col xs={12}>
              <TextRegister>
                {getLangLabel(
                  "OVER 1,199,553 AUCTION WINNERS SINCE 1993, LOWEST PRICE GUARANTEE!"
                )}
              </TextRegister>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <ImgTextSignUp
                onClick={() => {
                  setSignUpShow(true);
                }}
              >
                <ImageDesk alt="logo" className="ImageRegisterDesk" />

                <Text>{getLangLabel("REGISTER")}</Text>
              </ImgTextSignUp>
            </Col>
            <Coldisplay xs={0} md={0} lg={3}></Coldisplay>
          </Row>
        )}
      </Div>
    );
  }
};
