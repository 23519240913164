import { Search } from "@components/Search";
import { RecentSearches } from "@components/RecentSearches";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { useEffect, useState } from "react";
import { useAuth } from "@contexts/AuthContext";
// import { useBreakpoints } from "@hooks/useBreakpoints";
import { BannerDiv } from "@styles/home/styles";
import { Layout } from "@components/Layout";
import dynamic from "next/dynamic";
import { AdImageTextViwer } from "@components/Ads/AdImageTextViewer";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import { ImageRegister } from "@components/ImageRegister";
import { FLOWADS1, FLOWADS2 } from "@data/consts";
// import { AdsCarousel } from "@components/AdsPrelogin/Carousel";
// import { AdsPrestige } from "@components/AdsPrelogin/AdsPrestige";

const BannerV2 = dynamic(() => import("@components/AdsBanner/BannerV2"), {
  ssr: false,
});
const Ads = dynamic(() => import("@components/Flight/Ads"), {
  ssr: false,
});

const AdsPrestige = dynamic(
  () => import("@components/AdsPrelogin/AdsPrestige"),
  {
    ssr: false,
  }
);
type GeneralProps = {};

export const General: React.FC<GeneralProps> = ({}) => {
  // const { breakpoints } = useBreakpoints();
  // const middleBanner = useAssets("HOME_PAGE_MIDDLE_PAGE");
  const [refresh, setRefresh] = useState(false);
  const { getLangLabel } = useWhiteLabelLan();
  const { isLogged } = useAuth();
  // const [isMobile, setIsMobile] = useState(true);
  const [showSection, setShowSection] = useState("");

  const {
    isEnableAdsV2,
    isEnabledMainAds,
    enablePreloginAds,
    isLoadedExternalConfig,
  } = useLayoutStore(
    (state) => ({
      isEnableAdsV2: state.externalConfig?.isEnableAdsV2,
      isEnabledMainAds: state.externalConfig?.isEnabledMainAds,
      enablePreloginAds: state.externalConfig?.enablePreloginAds,
      isLoadedExternalConfig: state.isLoadedExternalConfig,
    }),
    shallow
  );

  useEffect(() => {
    if (isLoadedExternalConfig) {
      if (enablePreloginAds) {
        if (isLogged) {
          setShowSection(FLOWADS1);
        } else {
          setShowSection(FLOWADS2);
        }
      } else {
        setShowSection(FLOWADS1);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedExternalConfig]);

  return (
    <Layout>
      {showSection == FLOWADS2 && (
        <AdsPrestige
          title={getLangLabel("Hotels Stays Around the World")}
          subtitle={getLangLabel(
            "Explore Exceptional Properties Worldwide at Unbeatable Prices"
          )}
          adSection={"hotel"}
          bannerSection={"hotels"}
          space={"banner"}
        />
      )}
      {showSection == FLOWADS1 && (
        <>
          <BannerDiv>
            <BannerV2 section="hotels" space="banner" />
          </BannerDiv>
          <Search refresh={refresh} setRefresh={setRefresh} />
          <ImageRegister />
          <div style={{ minHeight: "500px", paddingTop: "3rem" }}>
            {isEnableAdsV2 && (
              <AdImageTextViwer section="hotels" space="space5" />
            )}
            {isLogged && !(isEnabledMainAds == false) && (
              <RecentSearches
                section="all"
                type="resentSearches"
                title={`${getLangLabel("YOUR RECENT SEARCHES")}`}
                subtitle={getLangLabel(
                  "KEEP TRACK AND GET THAT PERFECT HOTEL DEAL"
                )}
              />
            )}
            {!(isEnabledMainAds == false) && (
              <>
                <Ads
                  section="hotels"
                  space="space1"
                  title={getLangLabel(
                    "STAY AROUND THE WORLD FOR EVERY KIND OF TRIP"
                  )}
                  subtitle={getLangLabel(
                    "A TAILORED LIST OF DEALS THAT WILL GIVE YOU AN EXCUSE TO TRAVEL"
                  )}
                />
                <Ads
                  section="hotels"
                  space="space3"
                  title={getLangLabel("NIPPON WEEKS AROUND THE WORLD")}
                  subtitle={getLangLabel(
                    "7 NIGHTS STAYS IN EXCLUSIVE RESORTS AT THEIR BEST PRICE"
                  )}
                />
                <Ads
                  section="hotels"
                  space="space2"
                  title={getLangLabel("SUGGESTED DESTINATIONS JUST FOR YOU")}
                  subtitle={getLangLabel(
                    "A TAILORED LIST OF DEALS THAT WILL GIVE YOU AN EXCUSE TO TRAVEL"
                  )}
                />
                <Ads
                  section="hotels"
                  space="space4"
                  title={getLangLabel("CTC PARTNERS SPECIAL DEALS")}
                  subtitle={getLangLabel(
                    "SPECIAL DEALS FROM OUR PARTNERS THAT YOU CANT MISS"
                  )}
                />
              </>
            )}
          </div>
        </>
      )}
    </Layout>
  );
};
