import type { NextPage } from "next";

import { General } from "@components/Layout/Sites/General";
// import { Voyvio } from "@components/Layout/Sites/Voyvio";
// import { IS_VOYVIO } from "@helpers/config";

interface HomeProps {
  // configSite: ConfigSiteType;
}

const Home: NextPage<HomeProps> = ({}) => {
  return <General />;
};

export default Home;
