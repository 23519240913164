import { Col, Container, Row } from "react-bootstrap";

import styled from "styled-components";

export const ImgTextSignUp = styled(Col)`
  cursor: pointer;
`;

export const Div = styled(Container)`
  background-color: transparent;
  position: relative;
  text-align: center;
  padding: 0;
  margin: 0;

  @media (max-width: 768px) {
    width: 100% !important;
    max-width: 100% !important;
    padding-top: 15%;
  }
`;
export const Coldisplay = styled(Col)`
  display: none;
  @media only screen and (max-width: 992px) {
    display: block;
  }
`;

export const Text = styled.h4`
  position: absolute;
  top: 45%;
  font-size: 30px;
  left: 25%;
  color: #0054a6;
  font-weight: 100%;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 20px;
    top: 48%;
    left: 5%;
  }
`;

export const ImageDesk = styled.img`
  content: url("/images/register/MainSignDesk.png");
  max-width: 30rem;
  padding: 15px;
  vertical-align: middle;
`;

export const ImageMobil = styled.img`
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    display: block;
    margin-block-start: 3.33em;
    margin-block-end: 1.33em;
    margin-left: -23px;
  }
`;

export const RowStyle = styled(Row)`
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
`;

export const TextRegister = styled.p`
  text-align: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;
  color: #666;
  margin-top: 1.5rem;
  padding-bottom: 0;
  margin-bottom: 0;
  z-index: 1;
  position: relative;

  @media (max-width: 768px) {
    /* font-size: 20px; */
    margin-bottom: 1rem;
  }
`;
