import styled from "styled-components";

export const BannerDiv = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 992px) {
    display: none;
  }
  @media only screen and (min-width: 1024px) {
    min-height: 450px;
  }
`;
